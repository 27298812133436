import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { graphql, Link } from 'gatsby'

//Actions
import { getDesigner } from '../../actions/getDesigner'

//Packages
import Helmet from 'react-helmet'
import parse from 'url-parse'

//Components
import NavStreamlined from '../../components/nav/navStreamlined'
import FooterMinimalStatic from '../../components/nav/footerMinimalStatic'
import CreativeProfile from '../../components/creative/creativeProfile'

//Redux
const mapStateToProps = ({ designprofile, isDesignerTemplatesLoading }) => {
  return { designprofile, isDesignerTemplatesLoading }
}
const mapDispatchToProps = (dispatch) => ({
  getDesigner: (tag, displayname) => {
    dispatch(getDesigner(tag, displayname))
  },
})

const CreativeDetail = (props) => {
  const { getDesigner, designprofile, isDesignerTemplatesLoading } = props
  const [templateImage, setTemplateImage] = useState(null)
  const [keyword, setKeyword] = useState(null)

  useEffect(() => {
    //like componentDidMount
    const url = parse(window.location.href, true)

    console.log('profile/index - url')
    console.log(url)

    if (url.pathname) {
      const split = url.pathname.split('/')
      console.log('profile/index - split')
      console.log(split)

      getDesigner('single', split[2])
      setKeyword(split[2])
    }
  }, [])

  console.log('tag/index - designprofile')
  console.log(designprofile)

  let keywordCapitalized
  if (keyword) {
    keywordCapitalized = keyword.charAt(0).toUpperCase() + keyword.slice(1)
    keywordCapitalized = decodeURIComponent(keywordCapitalized)
  }

  return (
    <>
      <NavStreamlined />
      {/* <div id="navStreamlined-container"></div> */}
      {/* <Helmet
        title={`Storylava - ${keywordCapitalized} - Design Template`}
        meta={[
          {
            name: 'description',
            content: `A design templates about ${keywordCapitalized} to accelerate your business at Storylava`,
          },
        ]}
      /> */}

      <CreativeProfile
        context="single"
        designprofile={designprofile}
        setTemplateImage={setTemplateImage}
        templateImage={templateImage}
        isDesignerTemplatesLoading={isDesignerTemplatesLoading}
        keyword={keywordCapitalized}
      />

      <FooterMinimalStatic />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreativeDetail)
